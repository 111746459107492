module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-57579537-6","head":true,"enableWebVitalsTracking":true,"defer":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://sbx-www.swellenergy.com","exclude":["/partners"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://95dedf07b0094df7883b803bc63de788@o458648.ingest.sentry.io/5456505","environment":"sandbox","tracesSampleRate":0.2,"ignoreErrors":["Can't find variable: UET","UET is not defined","Not rendering React","is not a function","We couldn't load \"/page-data/sq/d","Failed to get the interest cohort: either it is unavailable, or preferences or content settings have denied access"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":false,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Swell Energy","short_name":"Swell","start_url":"/","background_color":"#f5f5f5","theme_color":"#2b96f4","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef63803abb4e5e1cda5bbec9200eda05"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
